import React, { useState } from 'react';
import { useAnimation } from 'framer-motion';
import useSidebarStore from '@/context/store/useSidebarStore.store';
import appRoutes from '../../../../../core/routes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DropdownMotionComponent } from '@/components/atom/MotionComponent';
import navigationUtils from '@/utils/navigationbar.utils';
import useModalStore from '@/features/modal/useModalStore';
import IconImage from '@/components/atom/Icon/IconImage';
import useRouterHook from '@/hooks/useRouterHook.hook';
import useAuth from '../../../../../features/auth/useAuth';

const NavigationSidebar = ({
	iconName,
	customIcon = false,
	inputCustomIcon = null,
	title,
	url,
	type = 'default',
	dropdownMenu,
	onClick
}) => {
	const { router } = useRouterHook();
	const { isLoggedIn } = useAuth();
	const { openModal } = useModalStore((state) => ({ openModal: state.openModal }));
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const { setCloseSidebar } = useSidebarStore((state) => ({
		setCloseSidebar: state.setCloseSidebar
	}));
	const controls = useAnimation();

	const onClickHandler = () => {
		switch (type) {
			case 'article': {
				if (url) {
					const to = appRoutes.ARTICLE + url;
					router.push(to);
					setCloseSidebar();
				}
				break;
			}
			case 'link':
				if (url) {
					openInNewTab(url);
					setCloseSidebar();
				}
				break;
			case 'click':
				if (onClick) {
					onClick();
				}
				break;
			case 'clickWithAuth':
				if (isLoggedIn) {
					if (onClick) {
						onClick();
					}
				} else {
					openModal('login');
				}
				break;
			case 'dropdown': {
				handleToggle();
				break;
			}
			default:
				if (url) {
					if (navigationUtils.pagesWithAuth(url)) {
						if (isLoggedIn) {
							router.push(url);
							setCloseSidebar();
						} else {
							openModal('login');
						}
					} else {
						router.push(url);
						setCloseSidebar();
					}
				}
				break;
		}
	};

	const openInNewTab = (url) => {
		if (typeof window !== 'undefined') {
			const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
			if (newWindow) newWindow.opener = null;
		}
	};

	const handleToggle = () => {
		setIsDropdownOpen(!isDropdownOpen);
		controls.start(isDropdownOpen ? 'closed' : 'open');
	};

	return (
		<React.Fragment>
			<div
				className={`
          flex flex-col w-full cursor-pointer p-1
          ${router.asPath === url ? 'rounded-lg bg-container-bg-primary' : ''}
          ${isDropdownOpen && 'rounded-lg bg-container-bg-primary'}
        `}
				onClick={onClickHandler}>
				<div className="flex w-full h-8 items-center justify-start gap-2">
					{customIcon ? (
						<React.Fragment>{inputCustomIcon}</React.Fragment>
					) : (
						<div className="flex w-6 h-6 aspect-square justify-start">
							<IconImage
								iconName={iconName}
								className={'w-full h-full'}
								status={router.asPath === url ? 'on' : 'off'}
							/>
						</div>
					)}

					{title && (
						<React.Fragment>
							<div className="flex w-full justify-between items-center">
								<p className="text-sm leading-none text-container-color-primary">{title}</p>
								{type === 'dropdown' && <KeyboardArrowDownIcon />}
							</div>
						</React.Fragment>
					)}
				</div>
				<DropdownMotionComponent controls={controls}>{dropdownMenu}</DropdownMotionComponent>
			</div>
		</React.Fragment>
	);
};

export default NavigationSidebar;
