import { cva } from 'class-variance-authority';
import { forwardRef } from 'react';
import { cn } from '@/utils/cn';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { LoadingRing } from '@/components/atom/Loading/LoadingDot';

const buttonVariant = cva('', {
	variants: {
		base: {
			base: 'rounded-lg w-full h-9 flex items-center justify-center px-4 py-2'
		},
		variant: {
			default: 'h-10 rounded-md bg-container-bg-secondary text-container-color-primary',
			white_border:
				'bg-container-bg-secondary btn-md text-sm w-fit border border-white border-solid text-white uppercase font-semibold',
			yellow:
				'bg-container-bg-accent2 btn-md text-sm w-fit border border-white border-solid text-black uppercase font-semibold rounded-sm',
			ghost:
				'btn-ghost border border-transparent bg-transparent text-current shadow-none outline-current',
			gradient_full: 'button-gradient_full text-sm font-semibold button-text-color',
			gradient_border: 'button-gradient_border border-2 border-transparent text-sm font-semibold',
			icon: 'bg-transparent border-0 uppercase select-none inline-flex size-fit p-1 bg-transparent active:border-0 focus:border-0 focus:outline-none focus-visible:outline-none'
		}
	},
	defaultVariants: {
		base: 'base',
		variant: 'default'
	}
});

const Button = forwardRef(
	({ children, className, variant, href, backButton, type, loading, disabled, ...props }, ref) => {
		const router = useRouter();
		const isDisabled = disabled ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer';
		const cvaClass = buttonVariant({ variant, className });

		// remove childContent wrapper if gradient button is no longer supported
		const childContent =
			variant === 'gradient_full' || variant === 'gradient_border' ? (
				<span
					className={
						variant === 'gradient_full'
							? 'button-gradient_full__text'
							: 'button-gradient_border__text'
					}>
					{children}
				</span>
			) : (
				children
			);

		const buttonContent = loading ? (
			<div className="w-6 h-6">
				<LoadingRing />
			</div>
		) : (
			childContent
		);

		if (backButton) {
			return (
				<button
					className={cn(cvaClass, isDisabled)}
					onClick={() => router.back()}
					{...props}
					ref={ref}>
					{buttonContent}
				</button>
			);
		}

		if (href) {
			return (
				<Link className={cn(cvaClass, isDisabled)} href={href} {...props}>
					{buttonContent}
				</Link>
			);
		}

		return (
			<button type={type ?? 'button'} className={cn(cvaClass, isDisabled)} {...props} ref={ref}>
				{buttonContent}
			</button>
		);
	}
);
Button.displayName = 'Button';

export default Button;
export { Button, buttonVariant };
