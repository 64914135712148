import { Button } from './Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function BackButton({ children, ...props }) {
	return (
		<Button {...props}>
			<ArrowBackIosIcon />
			<span
				className={'font-raleWay font-medium text-xl tablet:font-quicksandBold tablet:font-bold '}>
				{children}
			</span>
		</Button>
	);
}
