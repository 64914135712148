import React from 'react';

const cryptoList = ['btc', 'bsc', 'doge', 'eth', 'shib', 'usdt'];

const CryptoCoinListFooter = () => {
	return (
		<React.Fragment>
			<div className="grid gap-4 grid-cols-3 sm:grid-cols-4 md:grid-cols-6">
				{cryptoList.map((item, index) => (
					<React.Fragment key={index}>
						<div className="flex justify-center w-full">
							<div className="flex items-center aspect-[3/1]">
								<img src={`/images/Footer/${item}.svg`} className="w-full rounded-lg" />
							</div>
						</div>
					</React.Fragment>
				))}
			</div>
		</React.Fragment>
	);
};

export default CryptoCoinListFooter;
