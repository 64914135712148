const { create } = require('zustand');

const useCommunityChatStore = create((set) => ({
	openCommunityChat: false,
	setOpenCommunityChat: () => {
		set({ openCommunityChat: true });
	},
	setCloseCommunityChat: () => {
		set({ openCommunityChat: false });
	}
}));

export default useCommunityChatStore;
