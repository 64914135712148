export const LoadingDot = () => {
	return (
		<>
			<div className="w-full flex items-center justify-center">
				<div className="bg-gray-400 p-2 w-2 h-2 rounded-full animate-bounce blue-circle"></div>
				<div className="bg-gray-600 p-2 w-2 h-2 rounded-full animate-bounce green-circle"></div>
				<div className="bg-gray-800 p-2 w-2 h-2 rounded-full animate-bounce red-circle"></div>
			</div>
		</>
	);
};

export const LoadingRing = () => {
	return (
		<div className="w-full h-full rounded-full animate-spin border-2 border-solid border-black border-t-transparent"></div>
	);
};

export const LoadingPage = () => {
	return (
		<div className="fixed inset-0 w-screen h-screen">
			<div className="flex w-full h-full items-center justify-center">
				<div id="globalLoader">
					<img src="/favicon.ico" alt="favicon" />
					<div className="loader"></div>
				</div>
			</div>
		</div>
	);
};

export const LoadingSquare = () => {
	return (
		<div className="flex items-center justify-center gap-2">
			<div className="rounded-xl h-8 w-8 border-4 border-t-4 border-blue-500 animate-spin"></div>
			<div className="rounded-xl h-8 w-8 border-4 border-t-4 border-red-500 animate-spin"></div>
			<div className="rounded-xl h-8 w-8 border-4 border-t-4 border-green-500 animate-spin"></div>
		</div>
	);
};
