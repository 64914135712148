import React from 'react';
import BrandConfigStore from '@/context/store/brand-config';
import useRouterHook from '@/hooks/useRouterHook.hook';
import appRoutes from '../../../core/routes';
import useSidebarStore from '@/context/store/useSidebarStore.store';
import useAuth from '@/features/auth/useAuth';
import { useTranslation } from 'react-i18next';
import useModalStore from '@/features/modal/useModalStore';
import GlobalStateStore from '@/context/store/global-state';
import walletUtils from '@/utils/wallet.utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useWalletBalanceComponentStore from './useWalletBalanceComponentStore.store';
import IconImage from '../../atom/Icon/IconImage';

export default function Header() {
	const { routerGoToPage } = useRouterHook();
	const {
		config: { brandLogo }
	} = BrandConfigStore((state) => ({ config: state.config }));
	const { isLoggedIn } = useAuth();

	const onClickLogo = () => {
		routerGoToPage({ url: appRoutes.HOME });
	};

	return (
		<>
			<header className="flex sticky top-0 w-full h-14 items-center justify-between z-100 bg-navbar-bg-primary text-navbar-color-primary container-header">
				<div
					className="flex items-center h-12 aspect-aspect-logo cursor-pointer"
					onClick={onClickLogo}>
					<img src={brandLogo} alt="" className="w-full h-full object-contain" />
				</div>
				<div className="flex items-center gap-4">
					<NavigationDeposit />
					{isLoggedIn && <WalletBalanceComponent />}
					{/* {isLoggedIn && <WalletBalanceModalComponent />} */}
					<NavigationMenu />
				</div>
			</header>
		</>
	);
}

function WalletBalanceComponent() {
	const { router } = useRouterHook();
	const { openWalletBalanceModal } = useWalletBalanceComponentStore((state) => ({
		openWalletBalanceModal: state.openWalletBalanceModal,
		setToggleWalletBalanceModal: state.setToggleWalletBalanceModal
	}));
	const { openModal } = useModalStore((state) => ({
		openModal: state.openModal
	}));

	const { activeWalletBalance } = GlobalStateStore((state) => ({
		activeWalletBalance: state.activeWalletBalance
	}));

	const handleToggle = () => {
		if (!router.asPath.startsWith(appRoutes.PLAY)) return openModal('wallet_balance');
	};

	// if (router.asPath.startsWith(appRoutes.PLAY)) {
	// 	return null;
	// }

	return (
		<div
			className="relative flex items-center text-sm justify-between space-x-2 bg-navbar-wallet-color rounded-full min-w-[7rem] max-w-[10rem] h-10 p-2 cursor-pointer"
			onClick={handleToggle}>
			<div className="flex rounded-full h-5 w-5">
				<img
					src={activeWalletBalance?.currencyLogo}
					alt=""
					className="w-full h-full"
					loading="lazy"
				/>
			</div>
			<div className="flex w-full items-center max-w-[7rem] relative">
				<p className="flex w-full overflow-hidden truncate text-xs">
					{walletUtils.formatCurrency(
						activeWalletBalance?.totalBalance || 0,
						activeWalletBalance?.currencyType
					)}
				</p>
				{!appRoutes.PLAY ? (
					<KeyboardArrowDownIcon
						className={`aspect-square leading-none cursor-pointer ${
							openWalletBalanceModal ? 'transform rotate-180' : ''
						}`}
					/>
				) : null}
			</div>
		</div>
	);
}

function NavigationDeposit() {
	const { t } = useTranslation();
	const { isLoggedIn } = useAuth();
	const { openModal } = useModalStore((state) => ({ openModal: state.openModal }));
	const { router, routerGoToPage } = useRouterHook();

	const onClickHandler = () => {
		if (isLoggedIn) {
			routerGoToPage({ url: appRoutes.ACCOUNT_ROUTES.WALLET_ROUTES.DEPOSIT });
		} else {
			openModal('login');
		}
	};

	if (router.asPath.startsWith(appRoutes.PLAY)) {
		return null;
	}

	return (
		<div
			className="hidden md:flex cursor-pointer w-32 h-8 bg-red-500 gradient-button rounded-full"
			onClick={onClickHandler}>
			<div className="flex items-center justify-center w-full h-full gradient-full-button rounded-full">
				<span className="button-text-color text-base font-semibold">{t('menu.deposit')}</span>
			</div>
		</div>
	);
}

function NavigationMenu() {
	const { router } = useRouterHook();
	const { setOpenSidebar } = useSidebarStore((state) => ({
		setOpenSidebar: state.setOpenSidebar
	}));

	if (router.asPath.startsWith(appRoutes.PLAY)) {
		return null;
	}
	return (
		<React.Fragment>
			<div
				className="flex flex-row items-center w-6 h-6 aspect-square cursor-pointer"
				onClick={setOpenSidebar}>
				<IconImage iconName={'menu'} className={'w-full h-full'} />
			</div>
		</React.Fragment>
	);
}
