import dynamic from 'next/dynamic';

import { ToastContainer } from 'react-toastify';
import withMemo from '../../../hocs/withMemo';
import Sidebar from '../../organism/Sidebar';
import NavbarMobile from '../../organism/NavbarMobile';
import Header from '../../organism/Header/Header';
import Container from '../../organism/Container';
import Footer from '../../organism/Footer';
import PopupNoticeBoard from '../../organism/PopupNoticeBoard';
import BannerMainOut from '../../molecules/Banner/BannerMainOut';
import BannerGameCategory from '../../pages/GamesPage/components/BannerGameCategory';
import GameSearchPageComponent2 from '../../molecules/GameSearchComponent/components/GameSearchPageComponent2';
import LayoutModal from '../../../features/modal/LayoutModal';
import HeadLayout from '../HeadLayout';
import ScriptLayout from '../ScriptLayout';

const Chat = dynamic(() => import('@/components/organism/Chat'));

function Layout({ children }) {
	return (
		<>
			{/* head */}
			<HeadLayout />
			{/* all script */}
			<ScriptLayout />

			{/* toastify */}
			<ToastContainer
				position="top-right"
				autoClose={1000 * 5}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover={false}
			/>
			<Chat />
			<LayoutModal />
			<Header />
			<NavbarMobile />
			<div className="flex flex-col min-h-screen bg-container-bg-primary text-text-color-primary">
				<PopupNoticeBoard />
				<BannerGameCategory />
				<BannerMainOut />
				<div className="flex justify-center relative flex-grow">
					<Container>{children}</Container>
				</div>
			</div>
			<Footer />
			<GameSearchPageComponent2 />
			<Sidebar />
		</>
	);
}

export default withMemo(Layout);
