import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import useSidebarStore from '@/context/store/useSidebarStore.store';

const NavigationCloseSidebar = () => {
	const { setCloseSidebar } = useSidebarStore((state) => ({
		setCloseSidebar: state.setCloseSidebar
	}));
	return (
		<React.Fragment>
			<div className="flex justify-start">
				<CloseIcon className="text-container-color-primary" onClick={setCloseSidebar} />
			</div>
		</React.Fragment>
	);
};

export default NavigationCloseSidebar;
