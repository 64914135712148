import { useTranslation } from 'react-i18next';
import useAuth from '@/features/auth/useAuth';
import { MotionComponent } from '../../atom/MotionComponent';
import useModalStore from '../../../features/modal/useModalStore';
import { Button } from '@/components/button';
import { useRouter } from 'next/router';
import useSidebarStore from '../../../../context/store/useSidebarStore.store';

const AuthSectionComponent = ({ className = '', slideMode, disabledMotion = false }) => {
	const { t } = useTranslation();
	const { isLoggedIn } = useAuth();
	const openModal = useModalStore((state) => state.openModal);
	const router = useRouter();
	const { setCloseSidebar } = useSidebarStore((state) => ({
		setCloseSidebar: state.setCloseSidebar
	}));

	const handleClick = () => {
		router.push('/register');
		setCloseSidebar();
	};

	return (
		!isLoggedIn && (
			<MotionComponent slideMode={slideMode} disabledMotion={disabledMotion}>
				<div className={`flex flex-row items-center gap-3 ${className}`}>
					<Button
						variant="gradient_border"
						onClick={() => {
							openModal('login');
						}}>
						{t('btn.login')}
					</Button>
					<Button
						variant="gradient_full"
						onClick={() => {
							// openModal('register');
							handleClick();
						}}>
						{t('btn.register')}
					</Button>
				</div>
			</MotionComponent>
		)
	);
};

export default AuthSectionComponent;
