import Link from 'next/link';

const LinkComponent = ({ type, href, children }) => {
	if (type === 'web') {
		// Normal link within your Next.js app
		return <Link href={href}>{children}</Link>;
	} else if (type === 'link') {
		// External link
		return (
			<a href={href} target="_blank" rel="noopener noreferrer">
				{children}
			</a>
		);
	} else {
		// Handle unsupported link types or fallback
		return <span>{children}</span>;
	}
};

export default LinkComponent;
