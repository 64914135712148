import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import appRoutes from '../../../core/routes';
import BrandConfigStore from '@/context/store/brand-config';
import useSidebarStore from '@/context/store/useSidebarStore.store';
import { animationVariants } from '../../../../utils/framerMotion.utils';
import NavigationProfileSidebar from './components/NavigationProfileSidebar';
import NavigationSidebar from './components/NavigationSidebar';
import NavigationLanguageSidebar from './components/NavigationLanguageSidebar';
import NavigationSupportedCurrencySidebar from './components/NavigationSupportedCurrencySidebar';
import NavigationCloseSidebar from './components/NavigationCloseSidebar';
import AuthSectionComponent from '../../molecules/AuthSectionComponent';
import useCommunityChatStore from '../Chat/stores/useCommunityChatStore.store';
import ProfileLogoutSectionComponent from '../../pages/ProfilePage/components/ProfileLogoutSectionComponent';
import useAuth from '../../../features/auth/useAuth';

const Sidebar = () => {
	const { t } = useTranslation();
	const { openSidebar, setCloseSidebar } = useSidebarStore((state) => ({
		openSidebar: state.openSidebar,
		setCloseSidebar: state.setCloseSidebar
	}));

	const { setOpenCommunityChat } = useCommunityChatStore();
	const { config } = BrandConfigStore((state) => ({
		config: state.config
	}));

	const navigationSetting = config?.navigationSetting;

	useEffect(() => {
		if (openSidebar) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
	}, [openSidebar]);

	const sortedNavigationCustom = navigationSetting?.sidebar?.custom?.item
		.sort((a, b) => a.order - b.order)
		.map((item, index) => {
			if (item.isActive) {
				if (item.isActive) {
					return (
						<NavigationSidebar
							key={index}
							iconName={item?.icon}
							title={item?.navigationName}
							url={item?.url}
							type={item?.type}
						/>
					);
				}
				return null;
			}
		});

	const { isLoggedIn } = useAuth();

	return (
		<>
			{openSidebar && (
				<div
					className={`
						fixed inset-0 w-full min-h-screen z-399 transition-opacity duration-300 ease-in-out
					`}
					onClick={setCloseSidebar}></div>
			)}
			<AnimatePresence>
				{openSidebar && (
					<motion.div
						initial="closed"
						animate="open"
						exit="closed"
						variants={animationVariants['fromRight']}
						transition={{ duration: 0.5, ease: 'easeInOut' }}
						onAnimationComplete={() => !openSidebar}
						className="flex flex-col fixed right-0 bottom-0 top-0 p-4 w-3/4 md:w-96 z-400 bg-navbar-bg-primary gap-1 overflow-x-auto">
						<NavigationCloseSidebar />
						<NavigationProfileSidebar />
						<AuthSectionComponent disabledMotion />
						<NavigationSidebar
							iconName={'promotion'}
							title={t('menu.promotions')}
							url={appRoutes.PROMOTION}
						/>
						<NavigationSidebar
							iconName={'deposit'}
							title={t('menu.deposit')}
							url={appRoutes.ACCOUNT_ROUTES.WALLET_ROUTES.DEPOSIT}
						/>
						<NavigationSidebar
							iconName={'swap'}
							title={t('menu.swap')}
							url={appRoutes.ACCOUNT_ROUTES.WALLET_ROUTES.SWAP}
						/>
						<NavigationSupportedCurrencySidebar />
						<NavigationSidebar
							iconName={'referral'}
							title={t('menu.referral')}
							url={appRoutes.REFERRAL}
						/>
						<NavigationSidebar
							iconName={'reward'}
							title={t('menu.myRewards')}
							url={appRoutes.REWARD}
						/>
						{sortedNavigationCustom}
						{(config?.showBrandChat === 'true' || config?.showBrandChat === true) && (
							<NavigationSidebar
								iconName={'communityChat'}
								title={t('menu.communityChat')}
								type="clickWithAuth"
								onClick={() => {
									setCloseSidebar();
									setOpenCommunityChat();
								}}
							/>
						)}

						<NavigationSidebar iconName={'faq'} title={t('menu.faq')} url={appRoutes.FAQ} />
						<NavigationSidebar
							type="link"
							iconName={'livesupport'}
							title={t('menu.liveSupport')}
							url={config?.liveChatScriptMobile}
						/>
						<NavigationLanguageSidebar />
						<div className="mt-[20px]">{isLoggedIn ? <ProfileLogoutSectionComponent /> : null}</div>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};

export default Sidebar;
