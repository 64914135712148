/* eslint-disable indent */
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import { FreeMode, Navigation } from 'swiper/modules';
import TitleSection from '../../atom/Title/TitleSection';
import { MotionComponent } from '../../atom/MotionComponent';
import GameCategoryImage from '../../atom/CategoryImage';
import navigationUtils from '../../../../utils/navigationbar.utils';
import useRouterHook from '@/hooks/useRouterHook.hook';
import gameCategoryUtils from '../../../../utils/gameCategory.utils';
import useGameSearchGameCategoryStore from '../../pages/GamesPage/stores/useGameSearchGameCategoryStore.store';
import useSearchGameBarStore from '../../organism/NavbarMobile/stores/useSearchGameBarStore.store';
import useGameStore from '@/context/store/useGameStore.store';

const GameCategoryComponent = ({
	slideMode,
	showTitle = false,
	navigation = false,
	centeredSlides = false,
	loop = false
}) => {
	const { t } = useTranslation();
	const { router, GoToReloadPage } = useRouterHook();
	const { setReset } = useGameSearchGameCategoryStore((state) => ({
		setReset: state.setReset
	}));

	const { setCloseSearchGameBar } = useSearchGameBarStore((state) => ({
		setCloseSearchGameBar: state.setCloseSearchGameBar
	}));
	const { gameCategoryData } = useGameStore();

	const sortedNavigationGameCategory = gameCategoryData
		.sort((a, b) => a.order - b.order)
		.map((item, index) => {
			if (item.isActive) {
				if (item.isActive) {
					const navbar = navigationUtils.getGameCategory(item?.navigationName);
					const onNavigationHandler = () => {
						setCloseSearchGameBar();
						setReset();
						GoToReloadPage({
							url: navbar.url
						});
					};
					return (
						<SwiperSlide
							key={index}
							className={`
								lg:flex-1
								relative
								flex justify-center py-2
								!w-16 md:!w-24 lg:!w-auto h-20 bg-gradient-to-b rounded-lg cursor-pointer
								${navbar.url === router.asPath ? 'primary-gradient-background' : 'bg-container-bg-secondary'}
							`}
							onClick={onNavigationHandler}>
							<div>
								<div className="flex w-full justify-center">
									<div className="aspect-square w-10 h-10">
										<GameCategoryImage
											categoryName={navbar.icon}
											className="w-full h-full object-contain"
											status={gameCategoryUtils.imageStatus(() => navbar.url === router.asPath)}
										/>
									</div>
								</div>
								<div
									className={`
										flex items-end justify-center text-xxs md:text-xs text-center leading-none break-words h-6
										${navbar.url === router.asPath ? 'text-primary-gradient-color-s' : 'icon-gradient-text-color'}
									`}>
									{t(navbar.title)}
								</div>
							</div>
						</SwiperSlide>
					);
				}
				return null;
			}
		});

	return (
		<>
			<MotionComponent slideMode={slideMode}>
				<div className="relative flex flex-col mb-section">
					{showTitle && <TitleSection title="Game Category" />}
					<div className="flex">
						<Swiper
							slidesPerView={'auto'}
							spaceBetween={4}
							loop={loop}
							freeMode={true}
							centeredSlides={centeredSlides}
							navigation={navigation}
							// autoplay={{
							// 	delay: 0,
							// 	disableOnInteraction: false // Continue autoplay after interaction
							// }}
							// speed={30000}
							modules={[FreeMode, Navigation]}
							className="mySwiper lg:flex lg:flex-row lg:w-full">
							{sortedNavigationGameCategory}
							{/* {gameCategoryData?.data?.map((item, index) => {
								const category = categoryList.get(item?.gamecategory);
								if (!category) {
									return null; // If the key doesn't exist in categoryList, don't render this item
								}

								return (
									<Fragment key={index}>
										<SwiperSlide key={index} className="w-20">
											<div className="flex flex-col items-center justify-center h-full bg-gradient-to-b from-[#003902] to-container-bg-accent3 rounded-lg p-1">
												<div className="flex items-center w-10 aspect-[2/3]">
													<GameCategoryImage
														categoryName={category.imageName}
														className="w-full h-full object-contain"
													/>
												</div>
												<p className="text-xs mt-auto text-center leading-none gradient-text-color break-words">
													{category.title}
												</p>
											</div>
										</SwiperSlide>
									</Fragment>
								);
							})} */}
							{/* {Array.from({ length: 10 }).map((item, index) => (
								<SwiperSlide key={index} className="w-20">
									<div className="flex flex-col items-center justify-center h-full bg-gradient-to-b from-[#003902] to-container-bg-accent3 rounded-lg p-2">
										<div className="w-10 aspect-[2/3] bg-red-500"></div>
										<p className="text-sm mt-auto leading-none gradient-text-color">Ctgry{index}</p>
									</div>
								</SwiperSlide>
							))} */}
						</Swiper>
					</div>
				</div>
			</MotionComponent>
		</>
	);
};

export default GameCategoryComponent;
