import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationSidebar from '../NavigationSidebar';
import ConstantDataStore from '@/context/store/constant-data';

const NavigationSupportedCurrencySidebar = () => {
	const { t } = useTranslation();
	const { currencies } = ConstantDataStore((state) => ({
		currencies: state.currencies
	}));

	const sortedCurrencies = currencies?.sort((a, b) => {
		if (a.currencytype === 'FIAT' && b.currencytype !== 'FIAT') {
			return -1; // Place FIAT currencies before others
		}
		if (a.currencytype !== 'FIAT' && b.currencytype === 'FIAT') {
			return 1; // Place other currencies after FIAT
		}
		return 0; // Keep original order if both have the same type
	});

	const currencyList = sortedCurrencies?.map((item, index) => {
		return (
			<Fragment key={index}>
				<div className="flex items-center justify-start leading-none h-8 p-1 rounded-lg gap-2">
					<img src={item?.currency_logo} alt="" className="h-6 w-6 aspect-square" />
					<p className="text-container-color-primary">{item?.currencyname}</p>
				</div>
			</Fragment>
		);
	});

	return (
		<>
			<NavigationSidebar
				iconName={'supportedCurrency'}
				title={t('menu.supportedCurrency')}
				type="dropdown"
				dropdownMenu={currencyList}
			/>
		</>
	);
};

export default NavigationSupportedCurrencySidebar;
