import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import BrandConfigStore from '@/context/store/brand-config';
import Button from './Button';

export default function ShowPasswordButton(p) {
	const { colors } = BrandConfigStore((state) => state.config.themeConfig.container);
	const changeHandler = (e) => {
		e.preventDefault();
		p.setShowPassword(!p.showPassword);
	};

	return (
		<Button tabIndex={-1} onClick={changeHandler} type="button" variant="icon">
			{p.showPassword ? (
				<AiOutlineEyeInvisible className="text-2xl" style={{ color: colors.primary }} />
			) : (
				<AiOutlineEye className="text-2xl" style={{ color: colors.primary }} />
			)}
		</Button>
	);
}
