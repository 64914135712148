import React from 'react';
// import Banner from './components/Banner';
// import AuthSectionComponent from '../../molecules/AuthSectionComponent';
// import GameSearchComponent from '../../molecules/GameSearchComponent';
// import GameFavoriteComponent from '../../molecules/GameFavoriteComponent';
// import GameCategoryComponent from '../../molecules/GameCategoryComponent';
// import GameProviderComponent from '../../molecules/GameProviderComponent';

const Container = ({ children }) => {
	return (
		<React.Fragment>
			<div className="flex w-full justify-center space-y-section bg-container-bg-primary container-body">
				<div className="w-full h-full">{children}</div>
				{/* {children} */}
				{/* <Banner />
				<div className="container-body space-y-section pb-3">
					<AuthSectionComponent />
					<GameProviderComponent />
					<GameSearchComponent />
					<GameCategoryComponent />
					<GameFavoriteComponent showTitle={true} />
				</div> */}
			</div>
		</React.Fragment>
	);
};

export default Container;
