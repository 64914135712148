import React from 'react';
import BankImage from '../../../../atom/BankImage';

const paymentList = {
	bank: ['BCA', 'BRI', 'MANDIRI', 'BNI', 'CIMB'],
	ewallet: ['DANA', 'OVO', 'GOPAY', 'LINKAJA'],
	pulsa: ['TELKOMSEL', 'XL']
};

const PaymentListFooter = () => {
	return (
		<React.Fragment>
			<div className="grid gap-2 grid-cols-3 sm:grid-cols-4 md:grid-cols-6">
				{paymentList.bank.map((item, index) => (
					<React.Fragment key={index}>
						<div className="flex justify-center w-full">
							<div className="aspect-[3/1]">
								<BankImage bankName={item} className={'w-full rounded-lg'} />
							</div>
						</div>
					</React.Fragment>
				))}
				{paymentList.ewallet.map((item, index) => (
					<React.Fragment key={index}>
						<div className="flex justify-center w-full">
							<div className="aspect-[3/1]">
								<BankImage bankName={item} className={'w-full rounded-lg'} />
							</div>
						</div>
					</React.Fragment>
				))}
				{paymentList.pulsa.map((item, index) => (
					<React.Fragment key={index}>
						<div className="flex justify-center w-full">
							<div className="aspect-[3/1]">
								<BankImage bankName={item} className={'w-full rounded-lg'} />
							</div>
						</div>
					</React.Fragment>
				))}
			</div>
		</React.Fragment>
	);
};

export default PaymentListFooter;
