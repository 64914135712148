import { Fragment } from 'react';

const methodList = ['metamask', 'trustwallet', 'walletconnect', 'coinbase', 'mastercard', 'visa'];

const PaymentMethodListFooter = () => {
	return (
		<>
			<div className="grid gap-4 grid-cols-3 sm:grid-cols-4 md:grid-cols-6">
				{methodList.map((item, index) => (
					<Fragment key={index}>
						<div className="flex justify-center w-full">
							<div className="flex items-center aspect-[3/1]">
								<img src={`/images/Footer/${item}.svg`} className="w-full rounded-lg" />
							</div>
						</div>
					</Fragment>
				))}
			</div>
		</>
	);
};

export default PaymentMethodListFooter;
