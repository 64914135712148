import React from 'react';
import GlobalStateStore from '@/context/store/global-state';
import useRouterHook from '@/hooks/useRouterHook.hook';
import appRoutes from '../../../../../core/routes';
import { Button } from '@/components/button';
import useAuth from '@/features/auth/useAuth';
import useUser from '@/features/user/userInfo/useUser';

const ProfileLogoutSectionComponent = () => {
	const { logoutLoggedInUser } = useUser();
	const auth = useAuth();
	const { routerGoToPage } = useRouterHook();
	const { setLogout } = GlobalStateStore((state) => ({
		setLogout: state.setLogout
	}));

	const onHandlerLogout = () => {
		auth.logout().then(() => {
			setLogout();
			logoutLoggedInUser();
			routerGoToPage({ url: appRoutes.HOME });
		});
	};

	return (
		<React.Fragment>
			<div className="flex w-full justify-center mb-section">
				<Button variant="gradient_border" onClick={onHandlerLogout}>
					Logout
				</Button>
			</div>
		</React.Fragment>
	);
};

export default ProfileLogoutSectionComponent;
