import React from 'react';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import appRoutes from '../../../../../core/routes';
import IconImage from '@/components/atom/Icon/IconImage';
import useRouterHook from '@/hooks/useRouterHook.hook';
import useSidebarStore from '@/context/store/useSidebarStore.store';
import useAuth from '@/features/auth/useAuth';
import useUser from '@/features/user/userInfo/useUser';

const NavigationProfileSidebar = () => {
	const { router, routerGoToPage } = useRouterHook();
	const { isLoggedIn } = useAuth();
	const { user } = useUser();
	const { setCloseSidebar } = useSidebarStore((state) => ({
		setCloseSidebar: state.setCloseSidebar
	}));

	const onClickHandler = () => {
		routerGoToPage({ url: appRoutes.ACCOUNT_ROUTES.PROFILE });
		setCloseSidebar();
	};

	if (!isLoggedIn) {
		return null;
	}

	return (
		<React.Fragment>
			<div
				className="flex w-full h-14 items-center justify-start gap-4 cursor-pointer"
				onClick={onClickHandler}>
				<div className="w-14 aspect-square">
					{/* <img src="" alt="" /> */}
					{/* <AccountCircleIcon className="w-full h-full text-container-color-primary" /> */}
					<IconImage
						iconName="profile"
						className="w-full"
						status={router.asPath === appRoutes.ACCOUNT_ROUTES.PROFILE ? 'on' : 'off'}
					/>
				</div>
				<div className="flex flex-col">
					<p className="text-sm text-container-color-primary">{user?.username}</p>
					<div className="flex">
						<p className="text-sm text-container-color-primary">{user?.memberLevel}</p>
						<WorkspacePremiumIcon className="text-container-color-primary" />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default NavigationProfileSidebar;
