const IconImage = ({ iconName, className, status = 'off' }) => {
	const list = {
		home: `/icons/navigation/${status}/home.png`,
		menu: `/icons/navigation/${status}/menu.png`,
		searchgame: `/icons/navigation/${status}/search.png`,
		wallet: `/icons/navigation/${status}/wallet.png`,
		profile: `/icons/navigation/${status}/profile.png`,
		livesupport: `/icons/navigation/${status}/live_chat.png`,
		promotion: `/icons/navigation/${status}/promotion.png`,
		deposit: `/icons/navigation/${status}/deposit.png`,
		withdraw: `/icons/navigation/${status}/withdraw.png`,
		swap: `/icons/navigation/${status}/swap.png`,
		referral: `/icons/navigation/${status}/referral.png`,
		reward: `/icons/navigation/${status}/reward.png`,
		communityChat: `/icons/navigation/${status}/community_chat.png`,
		faq: `/icons/navigation/${status}/faq.png`,
		supportedCurrency: `/icons/navigation/${status}/currency.png`,
		bank: `/icons/navigation/${status}/bank.png`,
		bethistory: `/icons/navigation/${status}/bet_history.png`,
		transactionhistory: `/icons/navigation/${status}/transaction_history.png`,
		login: `/icons/navigation/${status}/signin.png`,
		// register: `/icons/navigation/${status}/register.png`,
		register: '/icons/navigation/custom-button.gif'
	};

	const imagePath = list?.[iconName] ?? '';

	return (
		<>
			<img src={imagePath} alt="" className={className} />
		</>
	);
};

export default IconImage;
