import appRoutes from '../src/core/routes';

const getGameCategory = (category) => {
	const gameCategory = {
		slot: {
			iconName: 'slot',
			title: 'text.slots',
			url: appRoutes.GAMES_ROUTES.SLOT
		},
		casino: {
			iconName: 'casino',
			title: 'text.casino',
			url: appRoutes.GAMES_ROUTES.CASINO
		},
		lottery: {
			iconName: 'lottery',
			title: 'text.lottery',
			url: appRoutes.GAMES_ROUTES.LOTTERY
		},
		card: {
			iconName: 'card',
			title: 'text.cardGame',
			url: appRoutes.GAMES_ROUTES.CARD
		},
		sport: {
			iconName: 'sport',
			title: 'text.sports',
			url: appRoutes.GAMES_ROUTES.SPORT
		},
		fishing: {
			iconName: 'fishing',
			title: 'text.fishing',
			url: appRoutes.GAMES_ROUTES.FISHING
		},
		cockfight: {
			iconName: 'cockfight',
			title: 'text.cockfight',
			url: appRoutes.GAMES_ROUTES.COCKFIGHT
		},
		other: {
			iconName: 'other',
			title: 'text.others',
			url: appRoutes.GAMES_ROUTES.OTHER
		}
	};

	const get = gameCategory[category];

	if (!get) {
		return null;
	}

	return {
		icon: get?.iconName,
		title: get?.title,
		url: get?.url
	};
};

const getNavbarList = [
	{
		name: {
			original: 'Menu',
			translateName: 'text.menu'
		},
		iconName: 'menu'
	},
	{
		name: {
			original: 'Home',
			translateName: 'text.menu'
		},
		iconName: 'menu',
		url: appRoutes.HOME
	},
	{
		name: {
			original: 'Register',
			translateName: 'text.menu'
		},
		iconName: 'menu'
	},
	{
		name: {
			original: 'Search Game',
			translateName: 'text.menu'
		},
		iconName: 'menu',
		url: appRoutes.HOME
	},
	{
		name: {
			original: 'Live Chat',
			translateName: 'text.menu'
		},
		iconName: 'menu',
		url: appRoutes.HOME
	}
];

const pagesWithAuth = (url) => {
	const routes = [
		appRoutes.ACCOUNT_ROUTES.PROFILE,
		appRoutes.ACCOUNT_ROUTES.WALLET_ROUTES.ACCOUNT,
		appRoutes.ACCOUNT_ROUTES.WALLET_ROUTES.DEPOSIT,
		appRoutes.ACCOUNT_ROUTES.WALLET_ROUTES.SWAP,
		appRoutes.ACCOUNT_ROUTES.WALLET_ROUTES.WITHDRAW,
		appRoutes.REFERRAL,
		appRoutes.REWARD
	];
	return routes.includes(url);
};

const navigationUtils = {
	getGameCategory,
	getNavbarList,
	pagesWithAuth
};

export default navigationUtils;
