const BankImage = ({ bankName, className }) => {
	const list = {
		3: '/images/payment/3.png',
		AG: '/images/payment/AG.png',
		ANZ: '/images/payment/ANZ.png',
		BCA: '/images/payment/BCA.png',
		BI: '/images/payment/BI.png',
		BNI: '/images/payment/BNI.png',
		BNP: '/images/payment/BNP.png',
		BRI: '/images/payment/BRI.png',
		BSI: '/images/payment/BSI.png',
		BTN: '/images/payment/BTN.png',
		BTPN: '/images/payment/BTPN.png',
		CIMB: '/images/payment/CIMB.png',
		DANA: '/images/payment/DANA.png',
		DANAMON: '/images/payment/DANAMON.png',
		DBS: '/images/payment/DBS.png',
		GANESHA: '/images/payment/GANESHA.png',
		GOPAY: '/images/payment/GOPAY.png',
		HSBC: '/images/payment/HSBC.png',
		INDOSAT: '/images/payment/INDOSAT.png',
		JENIUS: '/images/payment/JENIUS.png',
		LINKAJA: '/images/payment/LINKAJA.png',
		MANDIRI: '/images/payment/MANDIRI.png',
		MASPION: '/images/payment/MASPION.png',
		MAYAPADA: '/images/payment/MAYAPADA.png',
		MEGA: '/images/payment/MEGA.png',
		OCBC: '/images/payment/OCBC.png',
		OVO: '/images/payment/OVO.png',
		PANIN: '/images/payment/PANIN.png',
		PERMATA: '/images/payment/PERMATA.png',
		SAKUKU: '/images/payment/SAKUKU.png',
		SINARMAS: '/images/payment/SINARMAS.png',
		TELKOMSEL: '/images/payment/TELKOMSEL.png',
		UOB: '/images/payment/UOB.png',
		XL: '/images/payment/XL.png'
	};

	const imagePath = list?.[bankName];

	return (
		<>
			<img src={imagePath} alt="" className={className} />
		</>
	);
};

export default BankImage;
