import { useEffect, useState } from 'react';

const useWindowHook = () => {
	const [screenWidth, setScreenWidth] = useState(0);

	useEffect(() => {
		// Update screenWidth with the actual window width after component mounts
		setScreenWidth(window.innerWidth);

		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		// Clean up the event listener on unmount
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const isMobile = screenWidth < 1024;

	const closeWindowTabWithTimeout = () => {
		setTimeout(() => {
			window.opener = null;
			window.open('', '_self');
			window.close();
		}, 8000);
	};

	return { screenWidth, isMobile, closeWindowTabWithTimeout };
};

export default useWindowHook;
