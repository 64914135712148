const { create } = require('zustand');

const useGameSearchGameCategoryStore = create((set, get) => ({
	gameList: [],
	length: 50,
	from: 0,
	limit: 50,
	gpId: [],
	gpIdInfo: {},
	search: '',
	gameCategory: '',
	orderBy: 'desc',
	loadingFilter: true,
	setGameList: (newGameList) => {
		set({ gameList: newGameList });
	},
	setLength: (length) =>
		set({
			length: get().length + length
		}),
	setFrom: () =>
		set({
			from: get().from + get().limit
		}),
	setLimit: (limit) =>
		set({
			limit: limit
		}),
	setGpId: (gpId) => {
		const newGpId = [gpId];
		set({
			gpId: newGpId
		});
	},
	setGpIdInfo: (gpIdInfo) => {
		set({
			gpIdInfo: gpIdInfo
		});
	},
	setPushGpId: (gpId) => {
		const curr = get().gpId;
		const newGpId = [...curr, gpId];
		set({
			gpId: newGpId
		});
	},
	setRemoveGpId: (gpId) => {
		const curr = get().gpId;
		const newGpId = curr.filter((item) => item !== gpId);
		set({
			gpId: newGpId
		});
	},
	setRemoveGpIdInfo: () => {
		set({
			gpIdInfo: {}
		});
	},
	setSearch: (search) =>
		set({
			search: search
		}),
	setGameCategory: (gameCategory) =>
		set({
			gameCategory: gameCategory
		}),
	setOrderBy: (orderBy) => {
		set({
			orderBy: orderBy
		});
	},
	setLoadingFilter: (loadingFilter) => {
		set({
			loadingFilter: loadingFilter
		});
	},
	setReset: () =>
		set({
			gameList: [],
			from: 0,
			gpId: [],
			search: ''
		})
}));

export default useGameSearchGameCategoryStore;
