import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import useRouterHook from '@/hooks/useRouterHook.hook';
import BrandConfigStore from '@/context/store/brand-config';
// import GlobalStateStore from '@/context/store/global-state';
import BrandArticles from '../../../../public/data/brandArticles.json';
import SocialMediaFooter from '../../molecules/SocialMediaFooter';
import navigationUtils from '../../../../utils/navigationbar.utils';
import PaymentListFooter from './components/PaymentListFooter';
import CryptoCoinListFooter from './components/CryptoCoinListFooter';
import PaymentMethodListFooter from './components/PaymentMethodListFooter';

// const logos = {
// 	coins: ['btc', 'bsc', 'doge', 'eth', 'shib', 'usdt'],
// 	prov: ['metamask', 'trustwallet', 'walletconnect', 'coinbase', 'mastercard', 'visa']
// };

const Footer = () => {
	const { t } = useTranslation();
	const { router, GoToReloadPage } = useRouterHook();

	const hiddenRoutes = ['/play'];

	const isHidden = hiddenRoutes.includes(router.pathname);

	// from here will be fix
	const [promos, setPromos] = useState([]);
	const {
		config: { themeConfig, brandName, brandLogo, navigationSetting }
	} = BrandConfigStore((state) => ({ config: state.config }));
	const { backgroundColor, colors } = themeConfig.footer;
	// const { setUrl, headerHeight } = GlobalStateStore((state) => ({
	// 	setUrl: state.setUrl,
	// 	headerHeight: state.headerHeight
	// }));
	const articles = BrandArticles?.footer;

	// const handleClick = (id) => {
	// 	if (id.includes('#')) {
	// 		if (router.pathname !== '/') {
	// 			setUrl(id);
	// 			router.push('/');
	// 		}

	// 		const elm = document.querySelector(id);
	// 		window.scrollTo(0, elm?.getBoundingClientRect().top + window.scrollY - headerHeight);
	// 	} else {
	// 		router.push(`/${id}`);
	// 	}
	// };

	// const sortArr = (a, b) => (a.order > b.order ? 1 : -1);

	useEffect(() => {
		const article = articles?.slice(0, 10).filter((article) => !!article.isPublish);
		setPromos(article);
	}, [articles]);

	if (isHidden) {
		return null;
	}

	return (
		<>
			{/* <div className="container-footer mb-16 md:mb-0 bg-container-bg-primary">Ini Footer</div> */}
			<div
				className="w-full font-robotoRegular"
				style={{
					background: backgroundColor.primary,
					color: colors.primary
				}}>
				<div className="container-footer mx-auto">
					<div className="">
						<div className="py-4">
							<div className="h-0.5 bg-footer-color-primary opacity-35 mb-4"></div>
							<PaymentListFooter />
							<div className="h-0.5 bg-footer-color-primary opacity-35 my-4"></div>
							<CryptoCoinListFooter />
							<div className="h-0.5 bg-footer-color-primary opacity-35 my-4"></div>
							<PaymentMethodListFooter />
						</div>
						<div
							className="h-0.5"
							style={{
								background: colors.primary,
								opacity: 0.35
							}}></div>
						<div className="my-8">
							<div className="grid grid-cols-2 mobile-l:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 ">
								<div className="col-span-1">
									<h2
										className="text-base font-semibold"
										style={{
											color: colors.secondary
										}}>
										{t('h2.ourGames')}
									</h2>
									<div
										className="text-base justify-start items-start flex flex-col mt-2"
										style={{
											color: colors.primary
										}}>
										{navigationSetting?.sidebar?.default?.item
											.sort((a, b) => a.order - b.order)
											.map((item, index) => {
												const navbar = navigationUtils.getGameCategory(item?.navigationName);
												if (item.isActive) {
													return (
														<div
															onClick={() => {
																GoToReloadPage({
																	url: navbar.url
																});
															}}
															key={index}>
															{t(navbar.title)}
														</div>
													);
												}
											})}
									</div>
								</div>
								<div className="col-span-1">
									<SocialMediaFooter type={'social media'} />
								</div>
								<div className="col-span-1">
									<h2
										className="text-base font-semibold"
										style={{
											color: colors.secondary
										}}>
										{t('h2.aboutUs')}
									</h2>
									<div
										className="text-base space-y-2 mt-2"
										style={{
											color: colors.primary
										}}>
										{/* <Link href="/">
										<a className="cursor-pointer block">{t('text.termsConditions')}</a>
									</Link> */}
										<Link href="/faq">
											<a className="cursor-pointer block">FAQ</a>
										</Link>
										{/* <Link href="/affiliate/login">
										<a className="cursor-pointer block">Affiliate</a>
									</Link> */}
									</div>
								</div>
								<div
									className="h-0.5 col-span-full lg:hidden"
									style={{
										background: colors.secondary,
										opacity: 0.2
									}}></div>
								<div className="col-span-2 mobile-l:col-span-3 mobile-xl:col-span-4 lg:col-span-3">
									<h2
										className="text-base font-semibold"
										style={{
											color: colors.secondary
										}}>
										{t('h2.article')}
									</h2>
									<div
										className="text-base text-th-main-text grid-cols-2 grid mobile-l:grid-cols-3 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-3 mt-2 "
										style={{
											color: colors.primary
										}}>
										{promos?.map((unit, index) => (
											<div key={index} className="mb-2">
												<a href={`/a/${unit.id.toLowerCase()}`} className="cursor-pointer">
													{unit.title}
												</a>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>

						<div
							className="h-0.5"
							style={{
								background: colors.primary,
								opacity: 0.35
							}}></div>
						<div className="py-8">
							<div className="gap-4 pb-24 mobile-xl:pb-0 flex flex-col md:flex-row md:items-center justify-center">
								{brandLogo ? (
									<img
										src={brandLogo}
										className="logoIcon w-4/12 sm:w-3/12 md:w-2/12 h-full object-contain"
									/>
								) : (
									<h1 className="xl:text-4xl">{brandName}</h1>
								)}
								<div className="flex items-center">
									<div className="space-y-2">
										<p
											className="text-sm "
											style={{
												color: colors.primary
											}}>
											{' '}
											© {new Date().getFullYear()} {brandName}. {t('text.allRightsReserved')} | 18+
										</p>
										<p
											className="text-sm "
											style={{
												color: colors.secondary
											}}>
											{brandName} {t('text.footerText')}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
