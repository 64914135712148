import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useRouterHook from '@/hooks/useRouterHook.hook';
import { animationVariants } from '../../../../utils/framerMotion.utils';
import BrandConfigStore from '@/context/store/brand-config';
import LinkComponent from '../../atom/LinkComponent';
import CloseIcon from '@mui/icons-material/Close';

const PopupNoticeBoard = () => {
	const { router } = useRouterHook();
	const { config } = BrandConfigStore((state) => ({
		config: state.config
	}));

	const bannerPopupBrand = config?.bannerPopUp?.bannerPopUp;

	const [openPopupNoticeBoard, setOpenPopupNoticeBoard] = useState(true);

	const showRoutes = ['/'];
	const isShow = showRoutes.includes(router.pathname);

	useEffect(() => {
		if (openPopupNoticeBoard) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
	}, [openPopupNoticeBoard]);

	const onCloseHandler = () => {
		setOpenPopupNoticeBoard(false);
	};

	if (!isShow || bannerPopupBrand?.isActive !== 'active') {
		return null;
	}

	return (
		<AnimatePresence>
			{openPopupNoticeBoard && (
				<div
					className="flex items-center justify-center fixed inset-0 z-999 bg-black/50"
					onClick={onCloseHandler}>
					<motion.div
						initial="closed"
						animate="open"
						exit="closed"
						variants={animationVariants['fromTop']}
						transition={{ duration: 0.5, ease: 'easeInOut' }}
						onAnimationComplete={() => !openPopupNoticeBoard}
						className="flex items-center justify-center w-4/5 max-w-xl max-h-[80vh] z-1000">
						<div
							className="absolute -right-3 -top-3 flex items-center justify-center w-7 h-7 rounded-full bg-blue-500"
							onClick={onCloseHandler}>
							<CloseIcon className="w-4 h-4 aspect-square text-container-color-primary" />
						</div>
						<LinkComponent type={bannerPopupBrand?.type} href={bannerPopupBrand?.redirect}>
							<div className="flex w-full h-full items-center justify-center">
								<img src={bannerPopupBrand?.img} alt="" className="w-full" />
							</div>
						</LinkComponent>
					</motion.div>
				</div>
			)}
		</AnimatePresence>
	);
};

export default PopupNoticeBoard;
