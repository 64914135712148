const GameCategoryImage = ({ categoryName, className, status = 'off' }) => {
	const list = {
		lottery: `/icons/game-category/${status}/lottery.png`,
		card: `/icons/game-category/${status}/card.png`,
		slot: `/icons/game-category/${status}/slot.png`,
		sport: `/icons/game-category/${status}/sport.png`,
		casino: `/icons/game-category/${status}/casino.png`,
		fishing: `/icons/game-category/${status}/fishing.png`,
		cockfight: `/icons/game-category/${status}/cockfight.png`,
		other: `/icons/game-category/${status}/other_game.png`
	};
	const imagePath = list?.[categoryName] ?? '';

	return (
		<>
			<img src={imagePath} alt="" className={className} />
		</>
	);
};

export default GameCategoryImage;
