import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationSidebar from '../NavigationSidebar';
import BrandConfigStore from '@/context/store/brand-config';
import FlagIcon from '../../../../atom/Icon/FlagIcon';

const NavigationLanguageSidebar = () => {
	const { t, i18n } = useTranslation();
	const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
	const { languages } = BrandConfigStore((state) => ({
		languages: state.languages
	}));

	const onChangeLanguage = (lang) => {
		if (lang) {
			i18n.changeLanguage(lang);
			window.localStorage.setItem('lang', lang);
			setSelectedLanguage(lang);
		}
	};
	const languageList = languages
		?.sort((a, b) => a?.order - b?.order)
		?.map((item, index) => {
			return (
				<Fragment key={index}>
					<div
						className="flex items-center justify-start leading-none h-8 p-1 rounded-lg gap-2"
						onClick={() => {
							onChangeLanguage(item?.langCode);
						}}>
						<div className="w-6 h-6 aspect-square rounded-full overflow-hidden flex justify-center items-center">
							<FlagIcon flagName={item?.langCode} />
						</div>

						<p className="text-container-color-primary">{item?.lang}</p>
					</div>
				</Fragment>
			);
		});

	const selectedLanguageComponent = (
		<>
			<div className="flex items-center justify-start leading-none h-8 rounded-lg">
				<div className="w-6 h-6 aspect-square rounded-full overflow-hidden flex justify-center items-center">
					<FlagIcon flagName={selectedLanguage} />
				</div>
			</div>
		</>
	);

	return (
		<>
			<NavigationSidebar
				customIcon={true}
				inputCustomIcon={selectedLanguageComponent}
				title={t('menu.language')}
				type="dropdown"
				dropdownMenu={languageList}
			/>
		</>
	);
};

export default NavigationLanguageSidebar;
