import { Fragment } from 'react';
import useRouterHook from '@/hooks/useRouterHook.hook';
import appRoutes from '../../../../../core/routes';
import BrandConfigStore from '@/context/store/brand-config';

const BannerGameCategory = () => {
	const { router } = useRouterHook();
	const { config } = BrandConfigStore((state) => ({ config: state.config }));
	const bannerGameCategory = config?.bannerGameCategory;

	const getImagePath = (device, category) => {
		return device === 'mobile'
			? bannerGameCategory?.mobile?.[category]
			: bannerGameCategory?.desktop?.[category];
	};

	const category = router.asPath.split('/')[2]; // Assuming this is how you get the category from the path

	const mobileImagePath = getImagePath('mobile', category);
	const desktopImagePath = getImagePath('desktop', category);

	const showRoutes = [
		appRoutes.GAMES_ROUTES.LOTTERY,
		appRoutes.GAMES_ROUTES.CARD,
		appRoutes.GAMES_ROUTES.SPORT,
		appRoutes.GAMES_ROUTES.SLOT,
		appRoutes.GAMES_ROUTES.CASINO,
		appRoutes.GAMES_ROUTES.FISHING,
		appRoutes.GAMES_ROUTES.OTHER
	];

	const isShow = showRoutes.includes(router.asPath);

	if (!isShow) {
		return null;
	}

	return (
		<Fragment>
			<div className="flex items-center justify-center w-full aspect-2/1 lg:aspect-[3/1] max-h-[600px] mb-section">
				{/* Mobile image, hidden on larger screens */}
				<img
					src={mobileImagePath}
					alt=""
					className="w-full max-w-[1920px] h-full aspect-2/1 lg:aspect-[3/1] object-contain bg-black lg:hidden"
					loading="lazy"
				/>
				{/* Desktop image, shown only on larger screens */}
				<img
					src={desktopImagePath}
					alt=""
					className="hidden lg:block w-full max-w-[1920px] h-full aspect-2/1 lg:aspect-[3/1] object-contain bg-black"
					loading="lazy"
				/>
			</div>
		</Fragment>
	);
};

export default BannerGameCategory;
