import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';
import BrandConfigStore from '@/context/store/brand-config';
import useRouterHook from '@/hooks/useRouterHook.hook';
import useWindowHook from '@/hooks/useWindowHook.hook';

const BannerMainOut = () => {
	const { router } = useRouterHook();
	const { isMobile } = useWindowHook();

	const showRoutes = ['/'];

	const isShow = showRoutes.includes(router.pathname);

	const { banners } = BrandConfigStore((state) => state.config);

	const [bannerList, setBannerList] = useState({
		desktop: [],
		mobile: []
	});

	useEffect(() => {
		const sortedDesktopBanners = banners?.desktop?.sort((a, b) => a.order - b.order);
		const sortedMobileBanners = banners?.mobile?.sort((a, b) => a.order - b.order);
		setBannerList({
			mobile: sortedMobileBanners,
			desktop: sortedDesktopBanners
		});
	}, [banners]);

	if (!isShow) {
		return null;
	}

	let bannerListComp = null;

	const chosenBannerList = isMobile ? bannerList.mobile : bannerList.desktop;

	if (chosenBannerList?.length) {
		bannerListComp = (
			<React.Fragment>
				{chosenBannerList.map((item, index) => (
					<SwiperSlide key={index}>
						<div className="flex items-center justify-center w-full aspect-2/1 lg:aspect-[3/1] max-h-[600px]">
							<img
								src={item?.img}
								alt=""
								className="w-full max-w-[1920px] aspect-2/1 lg:aspect-[3/1] object-contain bg-black"
								loading="lazy"
							/>
						</div>
					</SwiperSlide>
				))}
			</React.Fragment>
		);
	} else {
		bannerListComp = null;
	}

	return (
		<React.Fragment>
			<div className="w-full bg-white mb-section">
				<Swiper
					slidesPerView={1}
					spaceBetween={30}
					effect={'fade'}
					loop={true}
					navigation={false}
					pagination={{
						enabled: true,
						clickable: true
					}}
					autoplay={{
						delay: 3000
					}}
					grabCursor={true}
					modules={[Autoplay, EffectFade, Navigation, Pagination]}
					className="mySwiper w-full bg-black">
					{bannerListComp}
				</Swiper>
			</div>
		</React.Fragment>
	);
};

export default BannerMainOut;
