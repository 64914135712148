import useModalStore from './useModalStore';

export default function LayoutModal() {
	const { isOpen, modal } = useModalStore((state) => ({
		isOpen: state.modalIsOpen,
		modal: state.modal,
		closeModal: state.closeModal
	}));

	return (
		isOpen && (
			<div
				className="h-screen top-0 bottom-0 m-auto left-0 z-500 w-screen fixed bg-black/50 bg-container-bg-primary/50 mx-auto flex items-center justify-center"
				style={{ backdropFilter: 'blur(10px)', WebkitBackdropFilter: 'blur(10px)' }}>
				{modal}
			</div>
		)
	);
}
