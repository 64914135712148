import { useTranslation } from 'react-i18next';
import BrandConfigStore from '@/context/store/brand-config';
import useNavbarStore from '@/context/store/useNavbarStore.store';
import appRoutes from '../../../core/routes';
import Link from 'next/link';
import SearchGameBar from './components/SearchGameBar';
import useSearchGameBarStore from './stores/useSearchGameBarStore.store';
import useRouterHook from '@/hooks/useRouterHook.hook';
import withMemo from '../../../hocs/withMemo';
import IconImage from '../../atom/Icon/IconImage';
import useModalStore from '@/features/modal/useModalStore';
import useAuth from '@/features/auth/useAuth';

const NavbarMobile = () => {
	const { t } = useTranslation();
	const { openNavbar } = useNavbarStore((state) => ({
		openNavbar: state.openNavbar,
		setCloseNavbar: state.setCloseNavbar
	}));
	const { setOpenSearchGameBar, setCloseSearchGameBar } = useSearchGameBarStore((state) => ({
		setOpenSearchGameBar: state.setOpenSearchGameBar,
		setCloseSearchGameBar: state.setCloseSearchGameBar
	}));
	const { isLoggedIn } = useAuth();

	const { router, routerGoToPage } = useRouterHook();

	const hiddenRoutes = ['/play'];

	const isHidden = hiddenRoutes.includes(router.pathname);

	const { config } = BrandConfigStore((state) => ({
		config: state.config
	}));
	const { openModal } = useModalStore((state) => ({ openModal: state.openModal }));

	const onClickHome = () => {
		routerGoToPage({
			url: appRoutes.HOME
		});
		setCloseSearchGameBar();
	};

	const onClickLiveChat = () => {
		if (config?.scriptSetting?.livechat?.link && config?.scriptSetting?.livechat?.link !== '') {
			window.open(config?.scriptSetting?.livechat?.link, '_blank');
		}
	};

	const onClickRegister = () => {
		// openModal('register');
		router.push('/register');
	};

	const onClickProfile = () => {
		if (isLoggedIn) {
			routerGoToPage({
				url: appRoutes.ACCOUNT_ROUTES.PROFILE
			});
		} else {
			openModal('login');
		}
	};

	if (!openNavbar) {
		return null;
	}

	if (isHidden || !openNavbar) {
		return null;
	}

	return (
		<>
			<SearchGameBar />
			<div className="md:hidden fixed left-0 right-0 bottom-0 w-full h-16 bg-navbar-bg-primary z-300 ">
				<div className="flex flex-row w-full h-full py-2">
					<Navigation
						iconName={'home'}
						title={t('text.home')}
						onClick={onClickHome}
						url={appRoutes.HOME}
					/>
					<Navigation
						iconName={'searchgame'}
						title={t('text.searchgame')}
						onClick={() => {
							setOpenSearchGameBar();
						}}
					/>
					{!isLoggedIn ? (
						<Navigation
							type={'middleCenter'}
							iconName={'register'}
							title={t('text.register')}
							onClick={onClickRegister}
						/>
					) : (
						<Navigation
							type={'middleCenterLink'}
							iconName={'wallet'}
							title={t('text.wallet')}
							url={appRoutes.ACCOUNT_ROUTES.WALLET_ROUTES.DEPOSIT}
						/>
					)}
					<Navigation iconName={'profile'} title={t('text.profile')} onClick={onClickProfile} />
					<Navigation iconName={'livesupport'} title="Live Chat" onClick={onClickLiveChat} />
				</div>
			</div>
		</>
	);
};

export const Navigation = ({ iconName, title, onClick, type, url }) => {
	const { router } = useRouterHook();

	const onClickHandler = () => {
		if (onClick) {
			onClick();
		}
	};

	switch (type) {
		case 'link':
			return (
				<>
					<div className="flex-1">
						<Link href={url}>
							<a>
								<div className="flex flex-col items-center w-full h-full">
									<div className="flex w-7 h-7 aspect-square">
										<IconImage
											iconName={iconName}
											className={'w-full h-full'}
											status={url === router.asPath ? 'on' : 'off'}
										/>
									</div>
									<div className="my-auto"></div>
									<p className="flex h-auto leading-none items-center text-xxs text-container-color-primary">
										{title}
									</p>
								</div>
							</a>
						</Link>
					</div>
				</>
			);
		case 'middleCenterLink':
			return (
				<>
					<div className="flex-1">
						<Link href={url} className="flex flex-col items-center w-full h-full">
							<a className="flex justify-center relative w-full h-full">
								<div className="absolute flex justify-center items-center rounded-full h-14 w-14 -top-6 bg-navbar-bg-primary">
									<div className="flex w-10 h-10 aspect-square">
										<IconImage iconName={iconName} className={'w-full h-full'} />
									</div>
								</div>
								<p className="flex flex-grow items-end justify-center text-center text-xxs text-container-color-primary">
									{title}
								</p>
							</a>
						</Link>
					</div>
				</>
			);
		case 'middleCenter':
			return (
				<>
					<div className="flex-1 flex flex-col items-center w-full h-full" onClick={onClickHandler}>
						<div className="flex justify-center relative w-full">
							<div className="absolute flex justify-center items-center rounded-full h-14 w-14 -top-8 bg-navbar-bg-primary">
								<div className="flex w-[3.3rem] h-[3.3rem] aspect-square">
									<IconImage iconName={iconName} className={'w-full h-full'} />
								</div>
							</div>
						</div>
						<div className="my-auto"></div>
						<p className="flex h-auto leading-none items-center text-center text-xxs text-container-color-primary">
							{title}
						</p>
					</div>
				</>
			);
		default:
			return (
				<>
					<div className="flex-1 flex flex-col items-center w-full h-full" onClick={onClickHandler}>
						<div className="flex w-7 h-7 aspect-square">
							<IconImage
								iconName={iconName}
								className={'w-full h-full'}
								status={url === router.asPath ? 'on' : 'off'}
							/>
						</div>
						<div className="my-auto"></div>
						<p className="flex h-auto leading-none items-center text-center text-xxs text-container-color-primary">
							{title}
						</p>
					</div>
				</>
			);
	}
};

export default withMemo(NavbarMobile);
