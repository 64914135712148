const FlagIcon = ({ flagName, ...props }) => {
	const list = {
		['id-ID']: '/icons/language/id.webp',
		['en-US']: '/icons/language/us.webp',
		['es-ES']: '/icons/language/es.webp',
		['fr-FR']: '/icons/language/fr.webp',
		['hi-IN']: '/icons/language/in.webp',
		['it-IT']: '/icons/language/it.webp',
		['ja-JP']: '/icons/language/jp.webp',
		['ko-KR']: '/icons/language/kr.webp',
		['ms-MY']: '/icons/language/my.webp',
		['pt-PT']: '/icons/language/pt.webp',
		['ru-RU']: '/icons/language/ru.webp',
		['th-TH']: '/icons/language/th.webp',
		['tr-TR']: '/icons/language/tr.webp',
		['vi-VN']: '/icons/language/vn.webp',
		['zh-CN']: '/icons/language/cn.webp'
	};

	// const imagePath = list?.[imageColor]?.[iconName];
	const imagePath = list?.[flagName];

	return (
		<>
			<img src={imagePath} alt={flagName} {...props} />
		</>
	);
};

export default FlagIcon;
