import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import GameCategoryComponent from '../../../../molecules/GameCategoryComponent';
import { animationVariants } from '../../../../../../utils/framerMotion.utils';
import useSearchGameBarStore from '../../stores/useSearchGameBarStore.store';
import GameSearchComponent from '../../../../molecules/GameSearchComponent';

const SearchGameBar = () => {
	const { openSearchGameBar, setCloseSearchGameBar } = useSearchGameBarStore((state) => ({
		openSearchGameBar: state.openSearchGameBar,
		setCloseSearchGameBar: state.setCloseSearchGameBar
	}));

	// useEffect(() => {
	// 	if (openSearchGameBar) {
	// 		document.body.style.overflow = 'hidden';
	// 	} else {
	// 		document.body.style.overflow = '';
	// 	}
	// }, [openSearchGameBar]);
	return (
		<React.Fragment>
			{openSearchGameBar && (
				<div
					className={`
								fixed inset-0 w-full min-h-screen z-300 transition-opacity duration-300 ease-in-out
							`}
					onClick={setCloseSearchGameBar}
					onTouchMove={setCloseSearchGameBar}></div>
			)}
			<AnimatePresence>
				{openSearchGameBar && (
					<motion.div
						initial="closed"
						animate="open"
						exit="closed"
						variants={animationVariants['fromBottom']}
						transition={{ duration: 0.5, ease: 'easeInOut' }}
						onAnimationComplete={() => !openSearchGameBar}
						className="p-2 fixed inset-x-0 bottom-14 bg-container-bg-primary rounded-t-lg z-300">
						<GameSearchComponent slideMode="fromTop" />
						<GameCategoryComponent slideMode="fromBottom" />
					</motion.div>
				)}
			</AnimatePresence>
		</React.Fragment>
	);
};

export default SearchGameBar;
